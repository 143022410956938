import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Medium} from '../../classes/medium';
import {FormType} from '../../enum/form-type-enum';
import {IFormData} from '../../interfaces/form-data';
import {ImageType} from '../../enum/image-type-enum';
import {CSS_LAYOUT_CONFIG} from '../../consts';
import {MessageBoxTypeEnum} from '../../enum/message-box-type-enum';
import {VisualComponent} from '../../classes/visual-component';
import {MediumCategoryEnum} from "../../enum/medium-category-enum";

@Component({
  selector: 'app-form-image-loader',
  templateUrl: './form-image-loader.component.html',
  styleUrls: ['./form-image-loader.component.scss']
})
export class FormImageLoaderComponent implements OnInit {
  @Input() entity: Medium | VisualComponent;
  @Input() imageType: ImageType;
  @Output() imageEvent = new EventEmitter<IFormData<Medium | VisualComponent>>();
  selectedImage?: File;
  label = 'Select Image';
  selectedFileName = '';
  imagesUrl: string;
  config = CSS_LAYOUT_CONFIG.responsiveImageDimensions;
  MessageBoxType = MessageBoxTypeEnum;
  errorMessage: string;

  constructor() {
    this.imagesUrl = environment.imagesUrl;
    this.selectedFileName = this.label;
  }
  ngOnInit(): void {}

  onSelectFile(event: any): void {
    this.errorMessage = null;
    if (event.target.files && event.target.files[0]) {
      this.selectedImage = event.target.files[0];
      this.selectedFileName = this.selectedImage.name;
    } else {
      this.selectedFileName = this.label;
    }
  }

  async onUpload(): Promise<void> {
    if (this.selectedImage) {
      try {
        await this.validateImage(this.selectedImage);

        const data = {
          formType: FormType.INSERT,
          obj: this.entity,
          image: this.selectedImage,
          imageType: this.imageType
        } as IFormData<Medium | VisualComponent>;

        this.imageEvent.emit(data);
      } catch (error) {
        this.errorMessage = error instanceof Error ? error.message : error.toString();
      }
    }
  }

  async validateImage(file: File): Promise<void> {
    let maxUploadSize = this.config.teaser.imageUploadMaxSize;
    let validDimensions = [
      this.config.teaser.desktop.landscape,
      this.config.teaser.desktop.landscapeLarge,
      this.config.teaser.desktop.landscapeLargeSmallHeight,
      this.config.teaser.desktop.landscapeLargeNew
    ];

    if (this.isMediumOfCategorySlider(this.entity)) {
      maxUploadSize = this.config.slider.imageUploadMaxSize;
      validDimensions = [
          this.config.slider.desktop.landscape,
          this.config.slider.mobile.landscape
      ];
    }

    this.checkFileSize(file, maxUploadSize);
    this.checkFileType(file, this.config.imageUploadTypes);
    await this.checkImageDimensions(file, validDimensions);
  }

  checkFileSize(file: File, maxSize: number): void {
    const size = Math.round(file.size / 1000);
    if (size > maxSize) {
      throw new Error(`Only images with a maximum size of ${maxSize} kb may be uploaded. The current image has ${size} kb.`);
    }
  }

  checkFileType(file: File, allowedImageTypes: string[]): void {
    const isValid = allowedImageTypes.some(type => file.type.includes(type));

    if (!isValid) {
      throw new Error(`Uploaded file has not a valid image type. Only ${allowedImageTypes.join(', ')} files are allowed.`);
    }
  }

  async checkImageDimensions(file: File, validDimensions: { width: number, height: number }[]): Promise<void> {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.onload = () => {
        const isValid = validDimensions.some(dimension =>
            img.width === dimension.width && img.height === dimension.height
        );

        if (!isValid) {
          const allowedSizes = validDimensions.map(d => `${d.width}x${d.height}px`).join(', ');
          reject(new Error(`Image dimensions do not match any of the allowed configurations. Allowed dimensions are: ${allowedSizes}. Uploaded image has dimensions ${img.width}x${img.height}px.`));
        } else {
          resolve();
        }
      };

      img.onerror = () => {
        reject(new Error("Failed to load image for dimension check."));
      };

      img.src = URL.createObjectURL(file);
    });
  }

  isMediumOfCategorySlider(medium: Medium | VisualComponent): boolean {
    return medium.mediumCategory?.name === MediumCategoryEnum[MediumCategoryEnum.Slider];
  }
}
